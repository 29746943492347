var stickyHeader = true;

// Clone admin items from header into footer links list
// $("#menuAdmin li").each(function() {
//   $(this).clone().appendTo(".footerBox ul.footerLinks");
// });

// CTA width
function setCtaWidth() {
  let ctaWidth = $('.mainCallToAction .donate').css('width');
  $('a.menuMainAlt').css('width', ctaWidth);
  console.log(ctaWidth);
}

$(window).on('load resize', function(){
  setCtaWidth();
})